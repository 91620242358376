import React from "react"
import { Flex, IconButton, Image, Link } from "@theme-ui/components"
import SpotifyIconPath from "./Icons/spotify_white.svg"
import AppleMusicIconPath from "./Icons/applemusic_white.svg"
import AmazonMusicIconPath from "./Icons/amazon_white.svg"
import YoutubeIconPath from "./Icons/youtube_white.svg"
import DeezerIconPath from "./Icons/deezer_white.svg"

export default ({ links }) => {
  return (
    <Flex
      sx={{
        justifyContent: "center",
      }}
    >
      {links.map((link) => {
        let iconPath = ""

        switch (link.type) {
          case "spotify":
            iconPath = SpotifyIconPath
            break
          case "applemusic":
            iconPath = AppleMusicIconPath
            break
          case "amazonmusic":
            iconPath = AmazonMusicIconPath
            break
          case "youtube":
            iconPath = YoutubeIconPath
            break
          case "deezer":
            iconPath = DeezerIconPath
            break
        }
        return (
          <IconButton
            key={`sociallinkssong${link.url}${link.type}`}
            sx={{
              mx: 1,
            }}
          >
            <Link
              href={link.url}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                src={iconPath}
                alt=""
                sx={{
                  width: "3.75rem",
                }}
              />
            </Link>
          </IconButton>
        )
      })}
    </Flex>
  )
}

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { jsx } from "theme-ui"
/** @jsx jsx */

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section, * as SectionElement from "../components/section"
import { Box, Embed, Image, IconButton, Text } from "@theme-ui/components"
import scrollDownIconPath from "../components/Icons/scroll_down.svg"
import Logo from "../components/Logo/Logo"
import MusicTeaser from "../components/MusicSection/MusicTeaser"
// import NewsSection from "../components/NewsSection/NewsSection"
import SocialLinks from "../components/SocialLinks"
import { Link } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      headerBackgroundVideo: file(
        relativePath: { eq: "background_video.mp4" }
      ) {
        publicURL
      }
      headerBackground: file(relativePath: { eq: "background_header.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <Section
        backgroundVideoSrc={data.headerBackgroundVideo.publicURL}
        backgroundImageData={data.headerBackground.childImageSharp.imageData}
        backgroundMaxWidth="100vw"
        py={6}
        sx={{
          minHeight: "100vh",
        }}
        contentSx={{
          height: "100%",
          maxWidth: "100%",
          minHeight: "30rem",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
          }}
        >
          <SocialLinks />
        </Box>
        <Box sx={{ justifySelf: "flex-end" }}>
          <Logo />
          <Box
            sx={{
              textAlign: "center",
              pt: 7,
            }}
          >
            <IconButton aria-hidden="true">
              <a href="#skip-nav">
                <Image
                  src={scrollDownIconPath}
                  alt=""
                  style={{
                    width: "3.75rem",
                  }}
                />
              </a>
            </IconButton>
          </Box>
        </Box>
      </Section>
      <div id="skip-nav" />
      <Section backgroundColor="white">
        <SectionElement.Header title="VIDEOS" />
        <Embed src="https://www.youtube.com/embed/46sa5Hqqxzo" />
      </Section>
      <Section backgroundColor="grey">
        <SectionElement.Header titleTextColor="white" title="MUSIK" />
        <MusicTeaser />
      </Section>
      {/* <Section px={5} backgroundColor="white">
        <SectionElement.Header title="NEWS" />
        <NewsSection />
      </Section> */}
      <Section
        backgroundColor="white"
        px={5}
        py={0}
        contentSx={{
          maxWidth: 2,
          py: 7,
          pb: 20,
        }}
      >
        <SectionElement.Header title="BIOGRAFIE" />
        <Text
          sx={{
            textAlign: "justify",
          }}
        >
          Daniel Mazzola (geb.in Stuttgart) ist ein in Berlin lebender Singer
          Songwriter. Schon früh startete er damit seine eigenen Songs zu
          schreiben. 2013 gewann er mit seinem Song "Wir erobern die Welt" die
          Big FM Newcomer - Show und erzielte seinen ersten Radio Hit. Als
          nächster Schritt folgte eine Video - Premiere von Daniels Song „Was
          erleben“ auf Backspin.TV. Momentan arbeitet er mit dem Produzenten und
          Komponisten Conrad Bekk in den Studios der Forster Straße an seinem
          Debütalbum. Die ersten Auskopplungen hieraus sind die beiden Singles
          „Feuerwerk“ & "Ich zu Wir".
        </Text>
      </Section>
      {/* <Section backgroundColor="grey">
        <SectionElement.Header titleTextColor="white" title="KONTAKT" />
      </Section> */}
      <Section backgroundColor="grey">
        <Box pb={3}>
          <Logo
            sx={{
              maxHeight: "3rem",
            }}
          />
        </Box>
        <Box pb={9}>
          <SocialLinks />
        </Box>
        <Link
          to="/imprint"
          sx={{
            variant: "links.default",
            color: "white",
          }}
        >
          IMPRESSUM
        </Link>
      </Section>
    </Layout>
  )
}
export default IndexPage

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Grid, Text } from "@theme-ui/components"
import SocialLinksSong from "../SocialLinksSong"
import { GatsbyImage } from "gatsby-plugin-image"

const data = [
  {
    title: "WIE DU TICKST",
    imageKey: "wieDuTickst",
    links: [
      {
        type: "spotify",
        url: "https://open.spotify.com/album/5x8EtHAIjQjekYqaeN9hUA",
      },
      {
        type: "applemusic",
        url:
          "https://music.apple.com/de/album/wie-du-tickst-single/1575649018",
      },
      {
        type: "amazonmusic",
        url: "https://www.amazon.de/dp/B098X8WK8P/ref=sr_1_1?__mk_de_DE=ÅMÅŽÕÑ&dchild=1&keywords=Daniel+mazzola+wie+du+tickst&qid=1625817511&sr=8-1",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/watch?v=Dwp6y9AmwO8",
      },
      {
        type: "deezer",
        url: "https://deezer.page.link/QCGRwAEUqdaxh9QbA",
      },
    ],
  },
  {
    title: "ICH ZU WIR (SUMMER MIX)",
    imageKey: "ichZuWirSingle",
    links: [
      {
        type: "spotify",
        url: "https://open.spotify.com/album/5YyuFlXbUGEnqYK63uDJyx",
      },
      {
        type: "applemusic",
        url:
          "https://music.apple.com/de/album/ich-zu-wir-summer-mix-single/id1426219625",
      },
      {
        type: "amazonmusic",
        url: "https://www.amazon.de/Ich-zu-Wir-Summer-Mix/dp/B07GC7GDHX",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/watch?v=e4Zat2bXlqk",
      },
      {
        type: "deezer",
        url: "https://www.deezer.com/de/album/70458042",
      },
    ],
  },
  {
    title: "ICH ZU WIR",
    imageKey: "ichZuWir",
    links: [
      {
        type: "spotify",
        url: "https://open.spotify.com/album/12oYrV3kfbqA4pwdqOa3oI",
      },
      {
        type: "applemusic",
        url: "https://music.apple.com/de/album/ich-zu-wir-single/1444374065",
      },
      {
        type: "amazonmusic",
        url: "https://www.amazon.de/Ich-zu-Wir/dp/B07KS1XMP7/ref=pd_ecc_rvi_1",
      },
      {
        type: "deezer",
        url: "https://www.deezer.com/de/album/79525732",
      },
    ],
  },
  {
    title: "FEUERWERK",
    imageKey: "feuerwerk",
    links: [
      {
        type: "spotify",
        url: "https://open.spotify.com/album/19Zg04Mzp6PKZskXi8FHtg",
      },
      {
        type: "applemusic",
        url: "https://music.apple.com/de/album/feuerwerk-single/1329118176",
      },
      {
        type: "amazonmusic",
        url: "https://www.amazon.de/Feuerwerk/dp/B078HGHR55",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/watch?v=2WXE2EMeQCI",
      },
      {
        type: "deezer",
        url: ": https://www.deezer.com/de/album/53769042",
      },
    ],
  },
]

const Teaser = ({ imageData, title, links }) => {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <GatsbyImage image={imageData} alt={`Cover ${title}`} />
      <Text
        variant="image.subtitle"
        color="white"
        sx={{
          textAlign: "center",
          mt: 4,
        }}
      >
        {title}
      </Text>
      <Box
        sx={{
          mt: 3,
        }}
      >
        <SocialLinksSong links={links} />
      </Box>
    </Box>
  )
}

export default () => {
  const images = useStaticQuery(graphql`
    query {
      wieDuTickst: file(relativePath: { eq: "WieDuTickst.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            width: 300
            formats: [AUTO, WEBP]
         )
        }
      }
      ichZuWir: file(relativePath: { eq: "IchZuWir.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            width: 300
            formats: [AUTO, WEBP]
         )
        }
      }
      ichZuWirSingle: file(relativePath: { eq: "IchZuWirSingle.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            width: 300
            formats: [AUTO, WEBP]
         )
        }
      }
      feuerwerk: file(relativePath: { eq: "Feuerwerk.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            width: 300
            formats: [AUTO, WEBP]
         )
        }
      }
    }
  `)

  return (
    <Grid columns={[1, 2, 3]} gap={12}>
      {data.map((item) => {
        return (
          <Teaser
            key={`musicteaser${item.title}`}
            imageData={images?.[item.imageKey]?.childImageSharp?.gatsbyImageData}
            title={item.title}
            links={item.links}
          />
        )
      })}
    </Grid>
  )
}

import React from "react"
import { Flex, Image, IconButton } from "@theme-ui/components"

import SpotifyIconPath from "./Icons/spotify_white.svg"
import AppleMusicIconPath from "./Icons/applemusic_white.svg"
import AmazonPath from "./Icons/amazon_white.svg"
import TwitterIconPath from "./Icons/twitter_white.svg"
import YoutubeIconPath from "./Icons/youtube_white.svg"
import InstagramIconPath from "./Icons/instagram_white.svg"
import FacebookIconPath from "./Icons/facebook_white.svg"

export default () => {
  return (
    <Flex
      sx={{
        justifyContent: "center",
      }}
    >
      <IconButton>
        <a href="https://open.spotify.com/artist/2PdbXjtrLilSCdKHPiTOsv">
          <Image
            src={SpotifyIconPath}
            alt=""
            sx={{
              width: "3.75rem",
            }}
          />
        </a>
      </IconButton>

      <IconButton>
        <a href="http://www.facebook.com/danielmazzolamusic">
          <Image
            src={FacebookIconPath}
            alt=""
            sx={{
              width: "3.75rem",
            }}
          />
        </a>
      </IconButton>
      <IconButton>
        <a href="https://www.instagram.com/daniel_mazzola">
          <Image
            src={InstagramIconPath}
            alt=""
            sx={{
              width: "3.75rem",
            }}
          />
        </a>
      </IconButton>
      <IconButton>
        <a href="https://www.youtube.com/dashmusictv">
          <Image
            src={YoutubeIconPath}
            alt=""
            sx={{
              width: "3.75rem",
            }}
          />
        </a>
      </IconButton>
      <IconButton>
        <a href="https://www.twitter.com/mazzolamusic">
          <Image
            src={TwitterIconPath}
            alt=""
            sx={{
              width: "3.75rem",
            }}
          />
        </a>
      </IconButton>
    </Flex>
  )
}
